/* UserAvatar.css */

.user-avatar-container {
  position: relative;
  display: inline-block;
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.status-indicator {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 10px;
  height: 10px;
  background-color: #4caf50;
  border-radius: 50%;
  animation: ripple 2s infinite; /* Ajuste a duração conforme necessário */
  opacity: 0.1; /* Ajuste a opacidade conforme necessário */
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.3;
  }
  100% {
    transform: scale(1);
    opacity: 0.6;
  }
}
